window.Vue = require('vue');

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

Vue.component('formulario-registro', require('../../componentes/sitio/FormularioRegistro.vue').default);

Vue.config.productionTip = false;
Vue.config.devtools = false;

if (document.getElementById("vue-formulario")) {
    const formulario_registro = new Vue({
        el: '#vue-formulario',
    });
}

$('#loginModal').on('hidden.bs.modal', function () {
    const event = new Event('evento-cerrar-modales'); 
    document.dispatchEvent(event);
});