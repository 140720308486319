<template>
    <div class="zp-fix-margin">
        <form :action="rutaregistroformulario" method="POST" v-on:submit="sendForm" class="zp-errors-border-bottom">

            <div class="zp-image-login-mshow">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                  </button>
            </div>
            
            <input type="hidden" name="_token" :value="csrf">
            <input type="hidden" name="originTab" value="register">
            <div class="form-group">
                <input type="text" name="nombre" placeholder="Nombre"
                    :class="!$v.formRegister.nombre.$anyError? 'form-control': 'form-control zp-form-error'"
                    v-model="$v.formRegister.nombre.$model" @keypress="onlyLetters" required  autocomplete="off">
            </div>
            <div class="zp-error-data" v-if="$v.formRegister.nombre.$dirty">
                <span v-if="!$v.formRegister.nombre.required" class="zp-error-span">
                    <i class="fas fa-exclamation-circle text-danger"></i>
                    Campo necesario
                </span>
            </div>
            <div class="form-group">
                <input type="text" name="apellidos" placeholder="Apellidos"
                    :class="!$v.formRegister.apellidos.$anyError? 'form-control': 'form-control zp-form-error'"
                    v-model="$v.formRegister.apellidos.$model" @keypress="onlyLetters" required  autocomplete="off">
            </div>
            <div class="zp-error-data" v-if="$v.formRegister.apellidos.$dirty">
                <span v-if="!$v.formRegister.apellidos.required" class="zp-error-span">
                    <i class="fas fa-exclamation-circle text-danger"></i>
                    Campo necesario
                </span>
            </div>
            <div class="form-group">
                <input type="text" name="rut" placeholder="RUT" v-rut:live maxlength="12"
                    :class="!$v.formRegister.rut.$anyError? 'form-control': 'form-control zp-form-error'"
                    v-model="$v.formRegister.rut.$model"
                    required @keypress="keypress" autocomplete="off">
            </div>
            <div class="zp-error-data" v-if="$v.formRegister.rut.$dirty">
                <span v-if="!$v.formRegister.rut.required" class="zp-error-span">
                    <i class="fas fa-exclamation-circle text-danger"></i>
                    Campo necesario
                </span>
                <span v-if="!$v.formRegister.rut.rutValidation && $v.formRegister.rut.required" class="zp-error-span">
                    <i class="fas fa-exclamation-circle text-danger"></i>
                    R.U.N. Inválido
                </span>
            </div>
            <div class="form-group">
                <input type="email" name="email" placeholder="Email"
                :class="!$v.formRegister.email.$anyError? 'form-control': 'form-control zp-form-error'"
                v-model="$v.formRegister.email.$model" required @keypress="keypress" autocomplete="off">
            </div>
            <div class="zp-error-data" v-if="$v.formRegister.email.$dirty">
                <span v-if="!$v.formRegister.email.required" class="zp-error-span">
                    <i class="fas fa-exclamation-circle text-danger"></i>
                    Campo necesario
                </span>
                <span v-if="!$v.formRegister.email.email && $v.formRegister.email.required" class="zp-error-span">
                    <i class="fas fa-exclamation-circle text-danger"></i>
                    Email inválido
                </span>
            </div>
            <div class="form-group">
                <input type="password" name="contraseña" placeholder="Contraseña"
                    :class="!$v.formRegister.contrasena.$anyError ? 'form-control': 'form-control zp-form-error'"
                    v-model="$v.formRegister.contrasena.$model" required @keypress="keypress" autocomplete="off">
            </div>
            <div class="zp-error-data" v-if="$v.formRegister.contrasena.$dirty">
                <span v-if="!$v.formRegister.contrasena.required" class="zp-error-span">
                    <i class="fas fa-exclamation-circle text-danger"></i>
                    Campo necesario
                </span>
                <span v-if="!$v.formRegister.contrasena.minLength && $v.formRegister.contrasena.required" class="zp-error-span">
                    <i class="fas fa-exclamation-circle text-danger"></i>
                    Al menos 8 caracteres
                </span>
            </div>
            <div class="form-group">
                <input type="password" name="contraseña_confirmation" placeholder="Confirmar Contraseña"
                    :class="!$v.formRegister.contrasena_confirmation.$anyError? 'form-control': 'form-control zp-form-error'"
                    v-model="$v.formRegister.contrasena_confirmation.$model" required @keypress="keypress" autocomplete="off">
            </div>
            <div class="zp-error-data" v-if="$v.formRegister.contrasena_confirmation.$dirty">
                <span v-if="!$v.formRegister.contrasena_confirmation.required" class="zp-error-span">
                    <i class="fas fa-exclamation-circle text-danger"></i>
                    Campo necesario
                </span>
                <span v-if="!$v.formRegister.contrasena_confirmation.sameAsPassword && $v.formRegister.contrasena_confirmation.required" class="zp-error-span">
                    <i class="fas fa-exclamation-circle text-danger"></i>
                    Las contraseñas no son iguales
                </span>
            </div>
            <div v-if="showErrorsBackend" class="zp-error-bag">
                <div v-for="error in inputs_errors">
                    <div class="zp-error-data">
                        <i class="fas fa-exclamation-circle text-danger"></i>
                        {{error[0]}}
                    </div>
                </div>
            </div>
            <button type="submit" class="btn bg-main-color general-btn mt-2"
                :disabled="$v.formRegister.$invalid">
                {{btnText}}
            </button>
        </form>
    </div>
</template>

<script>
    import { required, minLength, between, sameAs, email } from 'vuelidate/lib/validators'
    import { rutValidator, rutInputDirective } from "vue-dni";
    Vue.directive("rut", rutInputDirective);
    export default {
        name: "FormularioRegistro",
        props: [
            "inputs_old",
            "inputs_errors",
            "rutaregistroformulario"
        ],
        data: function() {
            return  {
                showErrorsBackend:false,
                formLoading: false,
                btnText:"Registrarse",
                formRegister:{
                    nombre:"",
                    apellidos:"",
                    email:"",
                    rut:"",
                    contrasena:"",
                    contrasena_confirmation:"",
                },
                csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            }
        },
        validations: {
            formRegister:{
                nombre:{
                    required,
                },
                apellidos:{
                    required,
                },
                rut:{
                    required,
                    rutValidation: rutValidator,
                },
                email:{
                    email,
                    required,
                },
                contrasena:{
                    required,
                    minLength: minLength(8)
                },
                contrasena_confirmation:{
                    required,
                    sameAsPassword: sameAs('contrasena')
                },
            }
        },
        watch: {
        },
        mounted() {
            if(this.inputs_old.originTab){
                if(this.inputs_old.originTab == "register"){
                    let self = this;
                    Object.entries(this.inputs_old).forEach( function(valor, indice, array){
                         if(array[indice][0]){
                            if(array[indice][0] != "_token"){
                                self.formRegister[array[indice][0]] = array[indice][1];
                            }
                         }
                    });
                    this.showErrorsBackend = true;
                    $('#loginModal').modal('show');
                    $('#pills-profile-tab').click();
                }
            }
            let self = this;
            document.addEventListener('evento-cerrar-modales', function (e) {
                self.$v.$reset();
                self.showErrorsBackend = false;
            }, false);
        },
        methods:{
            keypress(){
                this.showErrorsBackend = false;
            },
            sendForm (event) {
                if(!this.formLoading){
                    this.formLoading = true;
                    this.btnText = "Enviando...";
                    return true;
                }
                event.preventDefault();
                return false;
            },
            onlyNumber ($event) {
                this.showErrorsBackend = false;
               let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
               if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
                  $event.preventDefault();
               }
            },
            onlyLetters ($event) {
                this.showErrorsBackend = false;
               var regex = new RegExp("^[a-zA-Z ñÑ]+$");
                var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
                if (!regex.test(key)) {
                    event.preventDefault();
                    return false;
                }
            }
        }
    }
</script>

<style>

</style>
